*{
  margin: 0;
  padding: 0;
}
.acceptcookiebutton {
  background-color: #036aa1 !important;
  color: #fff !important;
  font-size: 15px;
  margin-left: 1rem;
  padding: 0.5rem 1rem !important;
  border-radius: 0.25rem !important;
  font-weight: "bold";
  transition: "background-color 0.3s";
}

.acceptcookiebutton:hover {
  background-color: #075179 !important;
}
.banner img {
  width: 100%;
  height: auto;
}
/* .accept-cookie-button {
  background-color: "rgb(3 105 161)";
  color: "#fff";
  font-size: "13px";
  margin-left: "1rem";
  padding: "0.5rem 1rem";
  border-radius: "0.25rem";
  font-weight: "bold";
  transition: "background-color 0.3s";
}

.accept-cookie-button:hover {
  background-color: #388E3C; 
} */